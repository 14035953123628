import * as React from "react";
import { css, Variants } from "@nju/css";

type SideStyleProps = Variants<typeof styles["wrapper"]>;

export type SideProps = SideStyleProps;

export function Side({ children, narrow }: React.PropsWithChildren<SideProps>) {
  return (
    <div
      className={styles.wrapper({
        narrow,
      })}
    >
      {children}
    </div>
  );
}

const styles = {
  wrapper: css({
    gridArea: "side",
    gridGap: "0",
    pt: "$20",
    "@fromTablet": {
      py: "$40",
      gridColumn: "9/13",
    },
    "@fromDesktop": {
      gridColumn: "9/13",
    },
    variants: {
      narrow: {
        true: {
          "@fromTablet": {
            gridColumn: "7/13",
          },
          "@fromDesktop": {
            gridColumn: "8/12",
          },
        },
      },
    },
  }),
};
