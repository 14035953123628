import { Header, HeaderBackgroundWrapper } from "@nju/feature-sales-header";
import { Footer } from "@nju/feature-portal-footer";
import { BlockContent, LayoutWrapper } from "@nju/ui";
import { LoginPage } from "@nju/feature-sales-login";
import { ClientSideOnly } from "../components/ClientSideRenderer";

export default function Login() {
  return (
    <LayoutWrapper>
      <HeaderBackgroundWrapper>
        <Header />
        <BlockContent>
          <ClientSideOnly>
            <LoginPage />
          </ClientSideOnly>
        </BlockContent>
      </HeaderBackgroundWrapper>
      <Footer />
    </LayoutWrapper>
  );
}
