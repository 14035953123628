import Bugsnag from "@bugsnag/js";
import { ok, isError } from "@nju/result";
import { client } from "@nju/http-client";
import { getScopeConfig, IScope } from "@nju/scope";

import { IIdentityProviderLoginResult } from "./types";
import { serverResponse } from "./validators";
import { setAuthInfoInStorage } from "../setAuthIntoInStorage";

type IParams = {
  code: string;
  redirectUri: string;
  scope?: IScope;
};

/**
 * This function handles login with identity provider for portal and sales app.
 */
export async function loginWithIdentityProvider({
  code,
  redirectUri,
  scope = "portal",
}: IParams): Promise<IIdentityProviderLoginResult> {
  const { realm, clientId } = getScopeConfig(scope);
  const body = new URLSearchParams();
  body.append("grant_type", "authorization_code");
  body.append("client_id", clientId || "unknown");
  body.append("code", code);
  body.append("redirect_uri", redirectUri);
  body.append("scope", "openid");

  const result = await client(
    `${process.env.NEXT_PUBLIC_IAM_HOST}/auth/realms/${realm}/protocol/openid-connect/token`,
    {
      method: "post",
      validator: serverResponse,
      body,
      headers: {
        "Accept-Language": "pl",
        Cookie: "KEYCLOAK_LOCALE=pl",
      },
    }
  );

  if (isError(result)) {
    Bugsnag.notify(result.err);
    return result;
  }

  const now = Date.now();

  const output = {
    accessToken: result.value.access_token,
    refreshToken: result.value.refresh_token,
    accessTokenExpirationDate: now + result.value.expires_in * 1000,
    refreshTokenExpirationDate: now + result.value.refresh_expires_in * 1000,
  };

  setAuthInfoInStorage(output, scope);

  return ok(output);
}
