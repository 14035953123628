import * as React from "react";
import { useMachine } from "@xstate/react";
import { useRouter } from "next/router";
import { Heading, BlockText, Separator, A, Button } from "@nju/ui";
import { getIdentityProviderLoginUrl } from "@nju/data-access-portal-user";
import { ErrorMessage, Input } from "@nju/form";
import { loginMachine } from "./login.machine";
import { salesRoutes } from "@nju/routes";
import { getFeatureFlagValue } from "@nju/feature-flags";
import Link from "next/link";
import { useSearchParams } from "next/navigation";

export function LoginForm() {
  const [technicalLoginToNWT, setTechnicalLoginToNWT] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setTechnicalLoginToNWT(getFeatureFlagValue("technicalLoginToNWT") === "1");
  }, []);

  const router = useRouter();
  const searchParams = useSearchParams();
  const code = searchParams.get("code") as string | undefined;

  const [current, send] = useMachine(loginMachine, {
    context: {
      authCode: code,
    },
    actions: {
      onSubmit: () => {
        router.push(salesRoutes.panel.get());
      },
    },
  });

  const { context, tags } = current;
  const isLoading = tags.has("loading");

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      ({ target }) => {
        send({ type: "SET_INPUT_VALUE", name: "email", value: target.value });
      },
      [send]
    );

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      ({ target }) => {
        send({
          type: "SET_INPUT_VALUE",
          name: "password",
          value: target.value,
        });
      },
      [send]
    );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> =
    React.useCallback(
      (event) => {
        event.preventDefault();
        send({ type: "SUBMIT" });
      },
      [send]
    );

  return (
    <div className="bg-white py-4 px-3 rounded-[8px] from-tablet:px-6">
      <Heading
        className="font-bold mb-2"
        font="ttfirs"
        tone="neutral"
        variant="h3"
      >
        Strefa konsultanta
      </Heading>
      <div data-cy="consultant-panel-text">
        <BlockText>
          Przejdź do panelu konsultanta nju. W kolejnym kroku poprosimy Cię o
          podanie danych do logowania.
        </BlockText>
      </div>
      <Separator size="s" tone="invisible" />
      {technicalLoginToNWT && (
        <div>
          <form onSubmit={handleSubmit}>
            <div>
              <Input
                label="Nazwa użytkownika"
                name="login"
                onChange={handleEmailChange}
                state={context.emailError ? "error" : undefined}
                value={context.email}
              />
              {context.emailError && (
                <ErrorMessage message={context.emailError} />
              )}
            </div>
            <Separator size="s" tone="invisible" />
            <div>
              <Input
                autoComplete="new-password"
                label="Hasło"
                name="password"
                onChange={handlePasswordChange}
                state={context.passwordError ? "error" : undefined}
                type="password"
                value={context.password}
              />
              {context.passwordError && (
                <ErrorMessage message={context.passwordError} />
              )}
            </div>
            {context.formError && <ErrorMessage message={context.formError} />}
            <Separator size="s" tone="invisible" />
            <div>
              <Button display="block" isLoading={isLoading} type="submit">
                zaloguj się
              </Button>
            </div>
          </form>
        </div>
      )}
      {!technicalLoginToNWT && (
        <div>
          <Link
            href={getIdentityProviderLoginUrl(
              "caap",
              salesRoutes.zaloguj.get(),
              "sales"
            )}
            passHref={true}
          >
            <A
              className="basis-[48%] [&>span]:absolute [&>span]:hidden [&>span]:w-0 [&>span]:h-0"
              size="m"
              testId="consultant-panel-button"
              variant="primary"
            >
              panel konsultanta
            </A>
          </Link>
        </div>
      )}
    </div>
  );
}
