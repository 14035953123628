import { ContractError } from "@nju/result";
import { getScopeConfig, IScope } from "@nju/scope";

export function getIdentityProviderLoginUrl(
  provider: "google" | "facebook" | "caap",
  redirectPath: string,
  scope: IScope
) {
  if (!redirectPath.startsWith("/")) {
    throw new ContractError({
      message: "`redirectUrl` must start with `/`",
    });
  }

  const redirectUri =
    typeof window === "undefined" ? "" : window.location.origin + redirectPath;

  const { realm, clientId } = getScopeConfig(scope);

  const params = new URLSearchParams();
  params.append("kc_idp_hint", provider);
  params.append("client_id", clientId);
  params.append("redirect_uri", redirectUri);
  params.append("response_type", "code");
  params.append("scope", "openid");

  return `${
    process.env.NEXT_PUBLIC_IAM_HOST
  }/auth/realms/${realm}/protocol/openid-connect/auth?${params.toString()}`;
}
