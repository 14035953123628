import Bugsnag from "@bugsnag/js";
import { client } from "@nju/http-client";
import { BasicError, err, isError, isHttpError, isOk, ok } from "@nju/result";
import { IScope, getScopeConfig } from "@nju/scope";
import { info } from "../info";
import { ILoginResult } from "./types";
import { serverResponse } from "./validators";
import { setAuthInfoInStorage } from "../setAuthIntoInStorage";

interface IParams {
  email: string;
  password: string;
  scope?: IScope;
}

export async function login({
  email,
  password,
  scope = "portal",
}: IParams): Promise<ILoginResult> {
  const { clientId, realm } = getScopeConfig(scope);

  const body = new URLSearchParams();
  body.append("grant_type", "password");
  body.append("client_id", clientId);
  body.append("username", email);
  body.append("password", password);

  const result = await client(
    `${process.env.NEXT_PUBLIC_IAM_HOST}/auth/realms/${realm}/protocol/openid-connect/token`,
    {
      method: "post",
      validator: serverResponse,
      doNotRefreshTokenOn401: true,
      headers: {
        "Accept-Language": "pl",
        Cookie: "KEYCLOAK_LOCALE=pl",
      },
      body,
    }
  );

  if (isError(result)) {
    if (isHttpError(result.err) && result.err.status === 401) {
      return err(
        new BasicError({
          code: "INVALID_CREDENTIALS",
          message: "Invalid email or password",
        })
      );
    }

    Bugsnag.notify(result.err);

    return result;
  }

  const now = Date.now();

  const output = {
    accessToken: result.value.access_token,
    refreshToken: result.value.refresh_token,
    accessTokenExpirationDate: now + result.value.expires_in * 1000,
    refreshTokenExpirationDate: now + result.value.refresh_expires_in * 1000,
  };

  setAuthInfoInStorage(output, scope);

  // @TODO restore this functionality for sales
  if (scope === "portal") {
    const userInfo = await info();

    if (isOk(userInfo)) {
      Bugsnag.setUser(
        userInfo.value.id,
        userInfo.value.email,
        `${userInfo.value.firstName} ${userInfo.value.lastName}`
      );
    }
  }

  return ok(output);
}
