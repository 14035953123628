import { Main, Side, Layout } from "@nju/ui";
import { LoginForm } from "./LoginForm";
import { useSellerLoginState } from "@nju/data-access-sales-seller";
import * as React from "react";
import { useRouter } from "next/router";
import { salesRoutes } from "@nju/routes";

export function LoginPage() {
  const { isLoggedIn } = useSellerLoginState();
  const router = useRouter();

  React.useEffect(() => {
    if (isLoggedIn) {
      router.push(salesRoutes.panel.get());
    }
  }, [isLoggedIn, router]);

  return (
    <Layout>
      <Main narrow={true}>
        <div className="mb-[-70px] overflow-hidden">
          <img
            className="w-full object-contain from-tablet:w-4/5"
            src="/images/pipik_seller.svg"
          />
        </div>
      </Main>
      <Side narrow={true}>
        <div className="from-tablet:ml-auto">
          <LoginForm />
        </div>
      </Side>
    </Layout>
  );
}
