import { assign, createMachine } from "xstate";
import { generalErrorMessage } from "@nju/utils";
import { login, loginWithIdentityProvider } from "@nju/data-access-portal-user";
import { salesRoutes } from "@nju/routes";
import { isError } from "@nju/result";

interface IContext {
  authCode?: string;
  email: string;
  password: string;
  emailError?: string;
  passwordError?: string;
  formError?: string;
}

type IEvent =
  | {
      type: "SET_INPUT_VALUE";
      name: "email" | "password";
      value: string;
    }
  | {
      type: "LOGIN_SUCCESSFUL";
    }
  | { type: "CREDENTIALS_ERROR" }
  | { type: "GENERAL_ERROR" }
  | { type: "SUBMIT" };

export const loginMachine = createMachine(
  {
    context: {
      email: "",
      password: "",
    },
    schema: {
      context: {} as IContext,
      events: {} as IEvent,
    },
    initial: "init",
    states: {
      init: {
        always: [
          {
            cond: "hasAuthCode",
            target: "loginWithCode",
          },
          {
            target: "ready",
          },
        ],
      },
      ready: {
        on: {
          SET_INPUT_VALUE: {
            actions: "setInputValue",
          },
          SUBMIT: {
            target: "validate",
          },
        },
      },
      validate: {
        entry: "validateForm",
        always: [
          {
            cond: "hasNoInputErrors",
            target: "loginWithForm",
          },
          {
            target: "ready",
          },
        ],
      },
      loginWithForm: {
        tags: ["loading"],
        invoke: {
          src: "loginWithForm",
        },
        on: {
          CREDENTIALS_ERROR: {
            actions: "setFormError",
            target: "ready",
          },
          LOGIN_SUCCESSFUL: {
            actions: "onSubmit",
          },
        },
      },
      loginWithCode: {
        tags: ["loading"],
        exit: "forgetAuthCode",
        invoke: {
          src: "loginWithCode",
        },
        on: {
          CREDENTIALS_ERROR: {
            actions: "setFormError",
            target: "ready",
          },
          LOGIN_SUCCESSFUL: {
            actions: "onSubmit",
          },
        },
      },
    },
  },
  {
    actions: {
      forgetAuthCode: assign<IContext, IEvent>(() => {
        return {
          authCode: undefined,
        };
      }),
      setFormError: assign<IContext, IEvent>((context, event) => {
        if (event.type === "CREDENTIALS_ERROR") {
          return {
            formError: "Hasło lub e-mail są niepoprawne.",
          };
        }

        if (event.type === "GENERAL_ERROR") {
          return {
            formError: generalErrorMessage,
          };
        }
        return context;
      }),
      validateForm: assign<IContext, IEvent>((context) => {
        let emailError: string | undefined;
        let passwordError: string | undefined;

        if (!context.email.trim()) {
          emailError = "Podaj adres e-mail.";
        }

        if (!context.password.trim()) {
          passwordError = "Podaj hasło.";
        }

        return {
          formError: undefined,
          emailError,
          passwordError,
        };
      }),
      setInputValue: assign<IContext, IEvent>((context, event) => {
        if (event.type === "SET_INPUT_VALUE") {
          return {
            [event.name]: event.value,
          };
        }
        return context;
      }),
    },
    guards: {
      hasAuthCode: ({ authCode }) => {
        return !!authCode;
      },
      hasNoInputErrors: ({ emailError, passwordError }) => {
        return emailError === undefined && passwordError === undefined;
      },
    },
    services: {
      loginWithForm: ({ email, password }) => {
        return async (callback) => {
          try {
            const result = await login({
              email,
              password,
              scope: "sales",
            });

            if (isError(result)) {
              return callback({ type: "CREDENTIALS_ERROR" });
            }

            return callback({
              type: "LOGIN_SUCCESSFUL",
            });
          } catch {
            callback({ type: "GENERAL_ERROR" });
          }
        };
      },
      loginWithCode: ({ authCode }) => {
        return async (callback) => {
          try {
            (
              await loginWithIdentityProvider({
                code: authCode || "",
                redirectUri: window.location.origin + salesRoutes.zaloguj.get(),
                scope: "sales",
              })
            ).unwrap();

            return callback({
              type: "LOGIN_SUCCESSFUL",
            });
          } catch {
            callback({ type: "GENERAL_ERROR" });
          }
        };
      },
    },
  }
);
