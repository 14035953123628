import * as React from "react";
import mergeRefs from "merge-refs";
import { mergeProps } from "@react-aria/utils";
import { useTextField } from "@react-aria/textfield";
import { useFocusRing } from "@react-aria/focus";
import { Variants } from "@nju/css";

import { styles } from "./styles";

export type InputStyleProps = Omit<
  Variants<typeof styles["wrapper"]>,
  "disabled" | "focus"
>;

export type InputStateStyleProp = Pick<
  Variants<typeof styles["wrapper"]>,
  "state"
>;

export interface InputProps extends InputStyleProps {
  autoFocus?: boolean;
  "aria-label"?: string;
  name: string;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  label?: string;
  type?: string;
  value?: string;
  defaultValue?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onValueChange?(value: string): void;
  autoComplete?: string;
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
  className?: string;
  maxLength?: number;
}

export const Input = React.memo(
  React.forwardRef<HTMLInputElement, InputProps>(function Input(
    props,
    forwardRef
  ) {
    const {
      autoComplete = "false",
      prefix,
      suffix,
      state,
      onChange,
      onValueChange,
      onBlur,
      className,
      ...rest
    } = props;

    const [value, setValue] = React.useState(props.defaultValue);
    const ref = React.useRef<HTMLInputElement>(null);

    const onValueChangeCallback = React.useCallback(
      function (value: string) {
        setValue(value);
        if (onValueChange) {
          onValueChange(value);
        }
      },
      [onValueChange]
    );

    const onBlurCallback = React.useCallback(function (event) {
      if (event.target.type === "text") {
        event.target.value = event.target.value.trim();
      }
    }, []);

    const { labelProps, inputProps } = useTextField(
      {
        ...rest,
        onBlur: onBlurCallback,
        onChange: onValueChangeCallback,
      },
      ref
    );

    const { focusProps, isFocused } = useFocusRing({
      isTextInput: true,
    });

    return (
      <div
        className={styles.wrapper({
          focus: isFocused,
          disabled: props.isDisabled || props.isReadOnly,
          background: props.background,
          state,
        })}
      >
        {prefix ? <div className={styles.prefix()}>{prefix}</div> : undefined}
        <div className={styles.inputWrapper()}>
          {props.label ? (
            <label
              className={styles.label({
                offside: isFocused || !!inputProps.value || !!value,
              })}
              {...labelProps}
            >
              {props.label}
            </label>
          ) : undefined}
          <input
            className={styles.input() + " " + className}
            ref={mergeRefs(ref, forwardRef)}
            {...mergeProps(inputProps, focusProps, {
              onChange,
              onBlur,
            })}
            autoComplete={autoComplete}
            id={props.name}
            maxLength={props.maxLength}
            name={props.name}
          />
        </div>
        {suffix ? <div className={styles.suffix()}>{suffix}</div> : undefined}
      </div>
    );
  })
);
