import * as React from "react";
import { css, Variants } from "@nju/css";

type LayoutStyleProps = Variants<typeof styles["wrapper"]>;

export type LayoutProps = LayoutStyleProps;
export function Layout({ children }: React.PropsWithChildren<LayoutProps>) {
  return <div className={styles.wrapper()}>{children}</div>;
}

const styles = {
  wrapper: css({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    gridTemplateAreas: `"side" "main"`,
    gap: 20,
    width: "100%",
    "@fromTablet": {
      gridTemplateAreas: `"main side"`,
      gridTemplateColumns: "repeat(12, 1fr)",
      gridTemplateRows: "1fr",
    },
  }),
};
