import * as React from "react";
import { css, Variants } from "@nju/css";

type MainStyleProps = Variants<typeof styles["wrapper"]>;

export type MainProps = MainStyleProps;
export function Main({ children, narrow }: React.PropsWithChildren<MainProps>) {
  return <div className={styles.wrapper({ narrow })}>{children}</div>;
}

const styles = {
  wrapper: css({
    alignItems: "start",
    gridArea: "main",
    pb: "$40",
    "@fromTablet": {
      py: "$40",
      gridColumn: "1/9",
    },
    "@fromDesktop": {
      gridColumn: "1/8",
    },
    variants: {
      narrow: {
        true: {
          "@fromTablet": {
            gridColumn: "1/7",
          },
          "@fromDesktop": {
            gridColumn: "2/8",
          },
        },
      },
    },
  }),
};
