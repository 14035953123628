import { useRouter } from "next/router";
import * as React from "react";

export function ClientSideOnly({ children }: React.PropsWithChildren<unknown>) {
  const [isReady, setIsReady] = React.useState(false);
  const router = useRouter();
  React.useEffect(() => {
    setIsReady(true);
  }, [router.isReady]);

  // eslint-disable-next-line unicorn/no-null
  return isReady ? <>{children}</> : null;
}
